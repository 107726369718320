import axios from "axios";
import { notification } from "antd";
import { API_URL } from "const";

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";

const HttpCodes = {
  OK: 200,
  UNAUTHORIZED: 401,
  ENTITY_TOO_LARGE: 413,
};

function checkStatus(response, isQuiet) {
  const data = response.data;
  if (response.status === HttpCodes.UNAUTHORIZED) {
    throw new Error("Unauthorized request.");
  } else if (response.status !== HttpCodes.OK) {
    !isQuiet &&
      notification.error({
        message: "Thông báo",
        description: "Có lỗi xảy ra, vui lòng thử lại.",
      });
    throw new Error("Request failed.");
  } else {
    if (data.error_code === 400) {
      !isQuiet &&
        notification.error({
          message: "Thông báo",
          description: data.message,
        });
      // throw new Error(data.message);
    }
  }
}

export const request = {
  post: async (url: String, data: any, isQuiet: Boolean = false) => {
    const response = await axios.post(url, data);
    checkStatus(response, isQuiet);
    return response.data.data;
  },
  put: async (url: String, data: any, isQuiet: Boolean = false) => {
    const response = await axios.put(url, data);
    checkStatus(response, isQuiet);
    return response.data.data;
  },
  get: async (url: String, params: any, isQuiet: Boolean = false) => {
    const response = await axios.get(url, { params });
    checkStatus(response, isQuiet);
    return response.data.data;
  },
  delete: async (url: String, params: any, isQuiet: Boolean = false) => {
    const response = await axios.delete(url, { params });
    checkStatus(response, isQuiet);
    return response.data.data;
  },
  rawGet: async (url: String, params: any) => {
    const response = await axios.get(url, { params });
    return response.data;
  },
};

export default request;
